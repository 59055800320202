import { Box, Button, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid2, Input, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import { APIProvider, Map, AdvancedMarker } from "@vis.gl/react-google-maps";
import { v4 as uuidv4 } from "uuid";

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocationSearchingOutlinedIcon from '@mui/icons-material/LocationSearchingOutlined';

import { fetchLocation } from "../../utils/utils";
import { ButtonCustom, TransparentCard } from "./KendraDetail";

const mapKey = process.env.REACT_APP_GMAP_API;

function SelectLocation({ location, onBack, onSave }) {

  const [isEditAddress, setIsEditAddress] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(location);

  useEffect(() => {
    if (!currentLocation || !currentLocation.lat || !currentLocation.lon) {
      fetchLocation((lat, lon) => {
        setCurrentLocation({ _id: uuidv4(), address: "", lat, lon });
      });
    }
  }, [currentLocation]);

  const onConfirm = () => {
    onSave(currentLocation);
  }

  const onCurrentLocation = () => {
    fetchLocation((lat, lon) => {
      setCurrentLocation({ ...currentLocation, lat, lon });
    });
  };

  const editAddress = () => {
    setIsEditAddress(true);
  };

  const closeEditAddress = () => {
    setIsEditAddress(false);
  };

  const addressUpdated = (address) => {
    setCurrentLocation({ ...currentLocation, address });
    setIsEditAddress(false);
  };

  const onMapClick = (e) => {
    const lat = e.detail.latLng.lat;
    const lon = e.detail.latLng.lng;
    setCurrentLocation({ ...currentLocation, lat, lon });
  };

  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lon = e.latLng.lng();
    setCurrentLocation({ ...currentLocation, lat, lon });
  };

  const _mapTemplate = () => {
    if (!currentLocation || !currentLocation.lat || !currentLocation.lon) {
      return;
    }
    return (
      <Box width={"100%"} height="54vh">
        <APIProvider apiKey={mapKey}>
          <Map
            key={currentLocation._id}
            mapId={currentLocation._id}
            defaultCenter={{
              lat: currentLocation.lat,
              lng: currentLocation.lon,
            }}
            center={{
              lat: currentLocation.lat,
              lng: currentLocation.lon,
            }}
            defaultZoom={14}
            gestureHandling="greedy"
            disableDefaultUI={true}
            onClick={(e) => onMapClick(e)}
          >
            <AdvancedMarker
              key={currentLocation._id}
              position={{
                lat: currentLocation.lat,
                lng: currentLocation.lon,
              }}
              draggable={true} // Enable dragging
              onDragEnd={(e) => onMarkerDragEnd(e)} // Handle drag end
            >
            </AdvancedMarker>
          </Map>
        </APIProvider>
      </Box>
    );
  }

  const _infoTemplate = () => {
    if (!currentLocation || !currentLocation.lat || !currentLocation.lon) {
      return;
    }
    return (
      <Grid2 size={12}>
        <TransparentCard variant="outlined">
          <CardContent>
            <Box display="flex" flexDirection="row" alignItems="center" sx={{ marginBottom: "8px" }}>
              <LocationOnOutlinedIcon fontSize="16" style={{ marginRight: "8px" }} />
              <Typography variant="body2">
                {currentLocation.address}
              </Typography>
              <IconButton
                onClick={editAddress}
                color="primary"
              >
                <EditIcon />
              </IconButton>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <LocationSearchingOutlinedIcon fontSize="16" sx={{ marginRight: "8px" }} />
              <Typography variant="body2">
                {currentLocation.lat}, {currentLocation.lon}
              </Typography>
            </Box>
          </CardContent>
        </TransparentCard>
      </Grid2>
    )
  }

  const _addressEditTemplate = () => {
    return (
      <Dialog
        open={isEditAddress}
        onClose={closeEditAddress}
        fullWidth={true}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const address = formJson.address;
            addressUpdated(address);
          },
        }}
      >
        <DialogTitle>Edit Address</DialogTitle>
        <DialogContent>
          <Input
            autoFocus={true}
            multiline
            required
            id="address"
            name="address"
            label="Address"
            variant="standard"
            defaultValue={currentLocation?.address}
            fullWidth={true}
            onFocus={(e) => e.target.select()}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditAddress(false)}>Cancel</Button>
          <Button type="submit">Save</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Box minHeight={"100vh"} width={"100vw"}>
      <Grid2 size={12} alignSelf={"flex-start"}>
        <Box display={"flex"} flexDirection={"row"} justifyContent={"start"} alignItems={"flex-start"} width={"100vw"} marginTop={4}>
          <Button
            onClick={() => onBack()}
            startIcon={<i className="material-icons">arrow_back</i>}
          />
          <Typography variant="h6">Select Location</Typography>
        </Box>
        <Box margin={3} borderRadius={4} overflow={"hidden"}>
          {_mapTemplate()}
        </Box>
        <Button
          onClick={() => onCurrentLocation()}
          startIcon={<i className="material-icons">my_location</i>}
          variant="outlined"
          sx={{ display: "flex", margin: "auto" }}
        >Current Location</Button>
        <Box margin={3}>
          {_infoTemplate()}
        </Box>
        <ButtonCustom
          onClick={() => onConfirm()}
          startIcon={<i className="material-icons">check</i>}
          variant="contained"
        >Confirm Location</ButtonCustom>
      </Grid2>
      {_addressEditTemplate()}
    </Box>
  );
}

export default SelectLocation;