import React, { useEffect, useState } from "react";
import { useActionData, useSubmit } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Grid2,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';

import Location from "./Location";
import Toast from "../common/Toast";

function KendraDetail({ kendra }) {
  const [toast, setToast] = useState(null);
  const [enableSave, setEnableSave] = useState(false);
  const [locations, setLocations] = useState(
    kendra.locations ? kendra.locations : []
  ); // Editable locations
  const submit = useSubmit();
  const saveResult = useActionData();

  const onSave = () => {
    //remove _id from locations
    const _locations = locations.map((loc) => {
      const { _id, ...location } = loc;
      return location;
    })
    const data = { locations: _locations }
    // Trigger submit hook when save button is clicked
    submit(data, {
      method: "post",
      encType: "application/json",
    });
  };

  useEffect(() => {
    if (saveResult) {
      if (saveResult.success) {
        setToast({
          key: new Date().getTime(),
          severity: "success",
          message: "Kendra locations saved successfully",
        });
      } else {
        setToast({
          key: new Date().getTime(),
          severity: "error",
          message: saveResult.error,
        });
      }
    }
  }, [saveResult]);

  useEffect(() => {
    //compare locations and kendra.locations to verify if the locations are updated
    // in case of update, enable the save button
    if (locations) {
      const isUpdated = JSON.stringify(kendra.locations) !== JSON.stringify(locations);
      setEnableSave(isUpdated);
    }
  }, [kendra, locations]);

  const onDeleteLocation = (id) => {
    const updatedLocations = locations.filter((loc) => loc._id !== id);
    setLocations(updatedLocations);
  };

  const onUpdateLocation = (location) => {
    // Update the location in the locations array, in case _id not found, add it to the array
    const locationIndex = locations.findIndex((loc) => loc._id === location._id);
    let updatedLocations = [...locations];
    if (locationIndex === -1) {
      updatedLocations.push(location);
    } else {
      updatedLocations[locationIndex] = location;
    }
    setLocations(updatedLocations);
  };

  const _kendraDetails = (kendra) => {
    const region = `${kendra.country} / ${kendra.state} / ${kendra.district} / ${kendra.taluka}`;
    const dayName = getDayName(kendra.weekDay);
    const dateNTime = `${dayName} | ${kendra.time}`;
    return (
      <Grid2 size={12} textAlign={"left"}>
        <PrimaryTypography style={{ display: "flex", alignItems: "center" }}>
          <LightModeOutlinedIcon fontSize="small" style={{ marginRight: "8px" }} />
          Kendra Details
        </PrimaryTypography>
        <TransparentCard variant="outlined">
          <CardContent>
            <Grid2 container spacing={0.8}>
              <Grid2 size={6}>
                <FormTitleTypography>Kendra Name</FormTitleTypography>
                <SecondaryTypography>{kendra.village}</SecondaryTypography>
              </Grid2>
              <Grid2 size={6}>
                <FormTitleTypography>Kendra Code</FormTitleTypography>
                <SecondaryTypography>{kendra.code}</SecondaryTypography>
              </Grid2>
              <Grid2 size={12}>
                <FormTitleTypography>Region</FormTitleTypography>
                <SecondaryTypography>{region}</SecondaryTypography>
              </Grid2>
              <Grid2 size={12}>
                <FormTitleTypography>Day &bull; Time</FormTitleTypography>
                <SecondaryTypography>{dateNTime}</SecondaryTypography>
              </Grid2>
            </Grid2>
          </CardContent>
        </TransparentCard>
      </Grid2>
    );
  };

  const _responsibles = (kendra) => {
    return (
      <Grid2 size={12}>
        <PrimaryTypography style={{ display: "flex", alignItems: "center" }}>
          <PeopleOutlineOutlinedIcon fontSize="small" style={{ marginRight: "8px" }} />
          Responsible Details
        </PrimaryTypography>
        <TransparentCard variant="outlined">
          <CardContent>
            {kendra.responsibles.map((responsible, index) => (
              <Box key={index}>
                <SecondaryTypography>{responsible.name}</SecondaryTypography>
                <FormTitleTypography>{responsible.mobile}</FormTitleTypography>
                <FormTitleTypography>{responsible.email}</FormTitleTypography>
                {index < kendra.responsibles.length - 1 && <GrayDivider />}
              </Box>
            ))}
          </CardContent>
        </TransparentCard>
      </Grid2>
    );
  };

  return (
    <Box>
      <Grid2
        container
        justifySelf={"center"}
        justifyContent={"center"}
        sx={{ width: { xs: "100vw", sm: "500px" }, padding: "24px" }}
        spacing={2}
      >
        <Grid2 size={12} textAlign={{ xs: "left", sm: "center" }}>
          <Typography variant="h6">Manage Kendra Locations</Typography>
        </Grid2>
        <Grid2 size={12} container justifyContent={"center"} spacing={1}>
          {_kendraDetails(kendra)}
          {_responsibles(kendra)}
          <Location
            location={locations[0]}
            label="Primary"
            onUpdate={onUpdateLocation}
          />
          <Location
            location={locations[1]}
            label="Alternate"
            onUpdate={onUpdateLocation}
            onDelete={(id) => onDeleteLocation(id)}
          />
        </Grid2>
        <ButtonCustom disabled={!enableSave} onClick={onSave} startIcon={<i className="material-icons">save</i>} variant="contained" >
          Save
        </ButtonCustom>
      </Grid2>
      {
        toast && <Toast keyProp={toast.key} severity={toast.severity} message={toast.message} />
      }
    </Box>
  );
}

export default KendraDetail;

const GrayDivider = styled(Divider)({
  backgroundColor: "rgba(255, 255, 255, 0.12)",
  height: "1px",
  marginTop: "8px",
  marginBottom: "8px",
});

export const TransparentCard = styled(Card)({
  backgroundColor: "transparent",
  borderWidth: "1px",
  borderColor: "rgba(255, 255, 255, 0.12)",
  borderRadius: "12px",
});

export const ButtonCustom = styled(Button)({
  borderRadius: "20px",
  display: "flex",
  margin: "auto",
});

export const PrimaryTypography = styled(Typography)({
  fontSize: "16px",
  letterSpacing: "0.50px",
  lineHeight: "2",
});

const SecondaryTypography = styled(Typography)({
  fontSize: "14px",
  letterSpacing: "0.15px",
});

const FormTitleTypography = styled(SecondaryTypography)({
  fontSize: "12px",
  color: "rgba(238, 238, 238, 0.50)",
});

const getDayName = (dayNumber) => {
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  return days[dayNumber];
};
