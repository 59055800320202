import React from "react";
import { useNavigation, useLoaderData } from "react-router-dom";
import { v4 as uuidv4 } from "uuid"; // Import uuid to generate unique ids

import { Box, Button, CircularProgress, Grid2, Typography } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';

import KendraDetail, { PrimaryTypography } from "../../components/kendra/KendraDetail";
import BhaktiChakra from "../../components/BhaktiChakra";

function DetailWrapper(inProgress, kendraResult) {

  if (kendraResult && kendraResult.success) {
    return;
  }

  // Extract the message and error state from actionData
  const _requestInProgress = (inProgress) => {
    if (!inProgress) {
      return;
    }
    return (
      <>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
          <CircularProgress size={56} color="primary" />
          <Typography variant="body1" color="text.secondary" marginTop={2}>
            Fetching details...
          </Typography>
        </Box>
      </>
    )
  }

  const _requestError = (kendraResult) => {
    if (!kendraResult || kendraResult.success) {
      return;
    }
    const { error } = kendraResult;
    return (
      <Grid2 size={12}>
        <HighlightOffIcon color="error" sx={{ fontSize: "56px" }} />
        <Typography variant="body1" color="text.secondary">
          Kendra details fetch {error}
        </Typography>
      </Grid2>
    )
  }

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "column" }}>
        <BhaktiChakra />
        <Grid2
          container
          alignContent={"space-between"}
          justifyContent={"center"}
          justifySelf={"center"}
          textAlign={"center"}
          size={{ xs: 12, sm: 6, md: 4 }}
          sx={{
            padding: "32px 20px 20px 20px",
            borderRadius: {
              xs: "48px 48px 0px 0px",
              sm: "48px",
            },
            background: "rgba(255, 255, 255, 0.07)",
            height: {
              xs: "60%", // height for small screens
              sm: "300px", // height for medium screens
            },
          }}
        >
          <Grid2 size={12}>
            <PrimaryTypography style={{ display: "flex", alignItems: "center" }}>
              <LightModeOutlinedIcon fontSize="small" style={{ marginRight: "8px" }} />
              Kendra Details
            </PrimaryTypography>
          </Grid2>

          {_requestInProgress(inProgress)}
          {_requestError(kendraResult)}

          <Box>
            <Button
              variant="contained"
              color="primary"
              // onClick={handleRetry}
              sx={{
                fontSize: "16px",
                borderRadius: "8px",
                visibility: "hidden",
              }}
              startIcon={<i className="material-icons">check_circle</i>}
            >
              Retry
            </Button>
          </Box>

        </Grid2>
      </Box>
    </>
  )
}

function KendrasPage() {
  const kendraResult = useLoaderData();
  const navigation = useNavigation();

  const inProgress = navigation.state === "submitting";

  const _requestSuccess = (kendraResult) => {
    if (!kendraResult || !kendraResult.success) {
      return;
    }
    const { kendra } = kendraResult;
    // add id to each kendra.locations
    kendra.locations = kendra.locations?.map((location) => {
      location._id = uuidv4();
      return location;
    });
    return (
      <KendraDetail kendra={kendraResult.kendra} />
    )
  }

  return (
    <>
      {_requestSuccess(kendraResult)}
      {DetailWrapper(inProgress, kendraResult)}
    </>
  );
}

export default KendrasPage;
