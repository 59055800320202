import React from "react";
import { Outlet } from "react-router-dom";

import api from "../../utils/api";
import { errorResponse, getEndpoint } from "../../utils/utils";
import { Grid2 } from "@mui/material";

function KendrasRoot() {
  return (
    <Grid2
      container
      justifyContent={"center"}
      sx={{
        minHeight: "100vh",
        width: "100vw",
      }}
    >
      <Outlet />
    </Grid2>
  );
}

export default KendrasRoot;

export async function saveKendraLocations({ request }) {
  const { endpoint, headers } = endpointHeaders(request);
  const locations = await request.json();
  // console.debug(endpoint, headers, locations);
  try {
    await api.put(`${endpoint}/locations`, locations, { headers });
    return {
      success: true,
    }
  } catch (error) {
    return errorResponse(error);
  }
}

export async function getKendra({ request }) {
  const { endpoint, headers } = endpointHeaders(request);

  try {
    const response = await api.get(endpoint, { headers });
    return {
      success: true,
      kendra: response.data,
    }
  } catch (error) {
    return errorResponse(error);
  }
}

function endpointHeaders(request) {
  const endpoint = getEndpoint(request.url.split("?")[0]); // Base endpoint
  const queryParams = new URLSearchParams(request.url.split("?")[1]);
  const token = queryParams.get("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  return { endpoint, headers };
}
