import React from 'react';
import bhaktichakra from './bhakti-chakra.svg';
import { Box } from '@mui/material';

function BhaktiChakra() {
  // Import result is the URL of your image
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
      <img src={bhaktichakra} alt="BhaktiChakra" style={{ width: "160px", paddingTop: "1rem", filter: "invert(1)", opacity: "0.3" }} />
    </Box>
  )
}

export default BhaktiChakra;