import React, { Fragment } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Root from "./pages/Root";
import ErrorPage from "./pages/Error";
import VerifyMobileRoot from "./pages/verifyMobile/VerifyMobileRoot";
import VerifyMobilePage from "./pages/verifyMobile/VerifyMobillePage";
import KendrasRoot from "./pages/kendras/KendrasRoot";
import KendrasPage from "./pages/kendras/KendrasPage";

import { sendEmailMobileVerificationRequest } from "./pages/verifyMobile/VerifyMobileRoot";
import { getKendra, saveKendraLocations } from "./pages/kendras/KendrasRoot";

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        id: "verify-mobile-email",
        path: "verify-mobile-email",
        element: <VerifyMobileRoot />,
        children: [
          {
            index: true,
            element: <VerifyMobilePage />,
            action: sendEmailMobileVerificationRequest,
          },
        ],
      },
      {
        id: "kendras",
        path: "kendras",
        element: <KendrasRoot />,
        children: [
          {
            id: "kendra",
            path: ":kendraId",
            element: <KendrasPage />,
            loader: getKendra,
            action: saveKendraLocations,
          },
        ],
      },
    ],
  },
]);

const theme = createTheme({
  palette: {
    background: {
      paper: '#1A242E',
    },
    primary: {
      main: '#82DFF3', // Primary color
    },
    error: {
      main: '#CF6679', // Error color
    },
    text: {
      primary: 'rgb(238, 238, 238)', // Default text color
      secondary: 'rgba(238, 238, 238, 0.74)', // Optional secondary text color
      success: '#00ff00', // Success text color
    },
  },
  typography: {
    allVariants: {
      color: 'rgb(238, 238, 238)', // Applies to all typography variants
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Fragment>
        <RouterProvider router={router} />
      </Fragment>
    </ThemeProvider>
  );
}

export default App;
