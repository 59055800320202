export const errorResponse = (error) => {
  if (!error.response) {
    return {
      success: false,
      code: "NETWORK_ERROR",
      error: "failed. Please check your network connection."
    }
  }
  if (error.response.status >= 500) {
    return {
      success: false,
      code: "SERVER_ERROR",
      error: "failed due to a server error. Please try again later."
    }
  }
  if (error.response.status === 401) {
    return {
      success: false,
      code: "LINK_EXPIRED",
      error: "failed, because the link has expired."
    }
  }
  return {
    success: false,
    code: error.response.data.code,
    error: error.response.data.message
  }
}

/**
 * Extract api endoint from the given url
 * @param {string} url 
 * @returns 
 */
export const getEndpoint = (url) => {
  // Match and remove everything before and including the first "/"
  const remainingUrl = url.replace(/^https?:\/\/[^/]+/, "");

  return remainingUrl;
}

// Function to fetch the user's current location (lat, long)
export const fetchLocation = (callback) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        callback(latitude, longitude); // Pass the fetched lat and lng to the callback
      },
      (error) => {
        console.error("Error fetching location", error);
        // Set default values if geolocation fails
        callback(22.54992, 72.8777);
      }
    );
  } else {
    alert("Geolocation is not supported by this browser.");
    // Set default values if geolocation is not available
    callback(22.54992, 72.8777);
  }
};

export const calculateZoomLevel = (location1, location2) => {
  const R = 6371; // Radius of the Earth in km
  const dLat = (location2.lat - location1.lat) * (Math.PI / 180);
  const dLng = (location2.long - location1.long) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(location1.lat * (Math.PI / 180)) *
    Math.cos(location2.lat * (Math.PI / 180)) *
    Math.sin(dLng / 2) *
    Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in km

  if (distance < 1) return 13;
  if (distance < 5) return 12;
  if (distance < 10) return 11;
  if (distance < 20) return 10;
  if (distance < 50) return 9;
  if (distance < 100) return 8;
  if (distance < 200) return 7;
  if (distance < 500) return 6;
  if (distance < 1000) return 5;
  return 5;
};
