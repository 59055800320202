import React, { useEffect, useState } from "react";
import {
  useSubmit,
  useNavigation,
  useActionData,
  useSearchParams,
} from "react-router-dom";
import {
  Button,
  CircularProgress,
  Typography,
  Grid2,
  Box,
} from "@mui/material";

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const VerifyMobilePage = () => {
  const submit = useSubmit();
  const [isReload, setIsReload] = useState(true);
  const navigation = useNavigation();
  const verificationResult = useActionData();
  let [searchParams] = useSearchParams();

  //get subject
  const mobileOrEmail = searchParams.get("mobile-or-email");

  useEffect(() => {
    if (mobileOrEmail) {
      setIsReload(false);
      submit(null, { method: "post" });
    }
  }, [submit, mobileOrEmail]);

  const inProgress = navigation.state === "submitting" || isReload;

  // Extract the message and error state from actionData
  const _requestInProgress = (inProgress) => {
    if (!inProgress) {
      return;
    }
    if (!mobileOrEmail) {
      return (
        <Grid2 size={12}>
          <HighlightOffIcon color="error" sx={{ fontSize: "56px" }} />
          <Typography variant="body1" color="text.secondary">
            Verification failed. Please check your request.
          </Typography>
        </Grid2>
      )
    }
    return (
      <>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
          <CircularProgress size={56} color="primary" />
          <Typography variant="body1" color="text.secondary" marginTop={2}>
            Verification in progress for
            <Typography component="span" color="text.primary" paddingX={0.5}>
              {mobileOrEmail}
            </Typography> ...
          </Typography>
        </Box>
      </>
    )
  }

  const _requestDone = (verificationResult) => {
    if (!verificationResult) {
      return;
    }
    console.debug("verificationResult", verificationResult);
    if (verificationResult.success) {
      return (
        <Grid2 size={12}>
          <TaskAltIcon color="success" sx={{ fontSize: "56px" }} />
          <Typography variant="body1" color="text.secondary">
            Verification for
            <Typography component="span" color="text.primary" paddingX={0.5}>
              {mobileOrEmail}
            </Typography>
            is succeded.
          </Typography>
        </Grid2>
      )
    } else {
      const { error } = verificationResult;
      return (
        <Grid2 size={12}>
          <HighlightOffIcon color="error" sx={{ fontSize: "56px" }} />
          <Typography variant="body1" color="text.secondary">
            Verification for
            <Typography component="span" color="text.primary" paddingX={0.5}>
              {mobileOrEmail}
            </Typography>
            {error}
          </Typography>
        </Grid2>
      )
    }
  }

  return (
    <Grid2
      container
      alignContent={"space-between"}
      justifyContent={"center"}
      textAlign={"center"}
      size={{ xs: 12, sm: 6, md: 6 }}
      sx={{
        position: "absolute",
        top: "40%",
        padding: "32px 20px 20px 20px",
        borderRadius: {
          xs: "48px 48px 0px 0px",
          sm: "48px",
        },
        background: "rgba(255, 255, 255, 0.07)",
        height: {
          xs: "60%", // height for small screens
          sm: "300px", // height for medium screens
        },
      }}
    >
      <Box>
        <Typography variant="h6">Email Verification</Typography>
      </Box>

      {_requestInProgress(inProgress)}
      {_requestDone(verificationResult)}

      <Box>
        <Button
          variant="contained"
          color="primary"
          // onClick={handleRetry}
          sx={{
            fontSize: "16px",
            borderRadius: "8px",
            visibility: "hidden",
          }}
          startIcon={<i className="material-icons">check_circle</i>}
        >
          Retry
        </Button>
      </Box>
    </Grid2>
  );
};

export default VerifyMobilePage;
