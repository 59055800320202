import { Box, Button, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid2, IconButton, Modal, styled, Typography } from "@mui/material";
import { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import GpsNotFixedOutlinedIcon from '@mui/icons-material/GpsNotFixedOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';

import { PrimaryTypography, TransparentCard, ButtonCustom } from "./KendraDetail";

import SelectLocation from "./SelectLocation";

function Location({ label, location, onUpdate, onDelete }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(location);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const updateLocation = () => {
    setIsModalOpen(true);
  }

  const onSave = (location) => {
    setCurrentLocation(location);
    setIsModalOpen(false);
    onUpdate(location);
  }

  const onDeleteLocation = () => {
    onDelete(currentLocation._id);
    setCurrentLocation(null)
    setConfirmDelete(false);
  }

  const _setLocationTemplate = () => {
    if (currentLocation && Object.keys(currentLocation).length > 0) {
      return;
    }
    return (
      <ButtonCustom
        onClick={() => updateLocation()}
        variant="outlined"
      >
        Set {label} Location
      </ButtonCustom>
    )
  }

  const _locationInfoTemplate = () => {
    if (!currentLocation) {
      return;
    }
    return (
      <Grid2 size={12}>
        <TransparentCard variant="outlined">
          <CardContent>
            <Box display="flex" flexDirection="row" alignItems="center" sx={{ marginBottom: "8px" }}>
              <LocationCityOutlinedIcon fontSize="16" style={{ marginRight: "8px" }} />
              <Typography variant="body2">
                {currentLocation.address}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <NearMeOutlinedIcon fontSize="16" sx={{ marginRight: "8px" }} />
              <Typography variant="body2">
                {currentLocation.lat}, {currentLocation.lon}
              </Typography>
            </Box>
          </CardContent>
        </TransparentCard>
      </Grid2>
    )
  }

  const _locationModalTemplate = () => {
    if (!isModalOpen) {
      return;
    }
    return (
      <LocationModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <SelectLocation location={currentLocation} onBack={() => setIsModalOpen(false)} onSave={onSave} />
        </>
      </LocationModal>
    )
  }

  const _actionsTemplate = () => {
    if (!currentLocation || Object.keys(currentLocation).length === 0) {
      return;
    }
    if (!onDelete) {
      return (
        <IconButton
          onClick={() => setIsModalOpen(true)}
        >
          <EditIcon color="primary" />
        </IconButton>
      )
    } else {
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <IconButton
            onClick={() => setIsModalOpen(true)}
          >
            <EditIcon color="primary" />
          </IconButton>
          <IconButton
            onClick={() => setConfirmDelete(true)}
          >
            <DeleteIcon color="error" />
          </IconButton>
        </div>
      )
    }
  }

  const _confirmDeleteTemplate = () => {
    if (!confirmDelete) {
      return;
    }
    return (
      <Dialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="error">
          {"Remove Alternate Location?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Alternate location will be removed, Are you sure you want to remove it?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(false)}>Cancel</Button>
          <Button onClick={onDeleteLocation} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    );
  }

  const _labelTemplate = (label) => {
    if (label === "Primary") {
      return (
        <PrimaryTypography style={{ display: "flex", alignItems: "center" }}>
          <MyLocationOutlinedIcon fontSize="small" style={{ marginRight: "8px" }} />
          {label} Location
        </PrimaryTypography>
      )
    } else {
      return (
        <PrimaryTypography style={{ display: "flex", alignItems: "center" }}>
          <GpsNotFixedOutlinedIcon fontSize="small" style={{ marginRight: "8px" }} />
          {label} Location
        </PrimaryTypography>
      )
    }
  }

  return (
    <Grid2 size={12}>
      <Box display="flex" flexDirection="column" alignItems="start">
        <Box display="flex" flexDirection="row" alignItems="center" justifyItems={"space-between"}>
          {_labelTemplate(label)}
          {_actionsTemplate()}
        </Box>
        {_setLocationTemplate()}
        {_locationInfoTemplate()}
        {_locationModalTemplate()}
        {_confirmDeleteTemplate()}
      </Box>
    </Grid2>
  )
}

export default Location;

const LocationModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  margin: 0,
  padding: 0,
  backdropFilter: "blur(10px)", // Add blur effect to the background
});