import React from "react";
import { Outlet } from "react-router-dom";
import { Grid2 } from "@mui/material";

import api from "../../utils/api";
import { errorResponse, getEndpoint } from "../../utils/utils";

import BhaktiChakra from "../../components/BhaktiChakra";

function VerifyMobileRoot() {
  return (
    <>
      <BhaktiChakra />
      <Grid2
        container
        justifyContent={"center"}
        sx={{
          height: "100vh",
          width: "100vw",
        }}
      >
        <Outlet />
      </Grid2>
    </>
  );
}

export default VerifyMobileRoot;

export async function sendEmailMobileVerificationRequest({ request }) {
  const endpoint = getEndpoint(request.url.split("?")[0]);
  const queryParams = new URLSearchParams(request.url.split("?")[1]);

  const token = queryParams.get("token")
  const mobileOrEmail = queryParams.get("mobile-or-email");

  if (!token || !mobileOrEmail) {
    return {
      mobileOrEmail,
      success: false,
      code: "INVALID_REQUEST",
      error: "failed. Please check your request"
    }
  }

  const requestBody = {
    token,
    mobileOrEmail,
  };

  try {
    await api.post(endpoint, requestBody);
    return {
      success: true
    }
  } catch (error) {
    return errorResponse(error);
  }
}
