import { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function Toast({ keyProp, severity, message }) {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (keyProp) {
      setOpen(true); // Open the Snackbar when the message changes
    }
  }, [keyProp]); // Re-run when `message` or `severity` changes

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar open={open} onClose={handleClose} autoHideDuration={3000} >
      <Alert
        onClose={handleClose}
        severity={severity ?? 'info'}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
